/* @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css"; */

:root {
  --red1: #e50000;
  --gray1:#ebebeb;
  --gray2:#e4e4e4;
  --black1: #494949;
  --black2: #727272;
  --black3: #c2c2c2;
  --green1: #32B377;
}

@font-face {
  font-family: "IRANSansMobile";
  src: url("./fonts/IRANSansMobile(FaNum).woff2");
}

@font-face {
  font-family: "IRANSansMobileBold";
  src: url("./fonts/IRANSansMobile(FaNum) Bold.woff2");
}


@font-face {
  font-family: "IRANSansMobileMedium";
  src: url("./fonts/IRANSansMobile(FaNum) Medium.woff2");
}

@font-face {
  font-family: "IRANSansMobileLight";
  src: url("./fonts/IRANSansMobile(FaNum) Light.woff2");
}

.body{
  font-family: "IRANSansMobile" !important;
}

.app {
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: "IRANSansMobile" !important;
}

.loginForm{
  width: 93%;
  min-width: 150px;
  /* margin: 0px 10px !important; */
  max-width: 420px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);
  padding: 20px;
}

.paper{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.avatar{
  margin: 20px;
  background-color: var(--red1) !important;
}

.form{
  width: 100%;
  margin-top: 10px;
}

.submit{
  margin-top: 30px !important;
  min-height: 50px;
}

.MuiFormHelperText-root{
  color: var(--red1) !important;
}

.dashboardMain{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: flex-start;
  justify-self: flex-start;
  height: 100vh;
}

.dashboardTitle{
  border-bottom: 2px solid var(--red1);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray1);
  position: relative;
}

.dashboardMiddle{
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex-grow: 1;
}

.dashbaordMenu{
  /* width: 40%; */
  width: 250px;
  max-width: 250px;
  min-width: 100px;
  background-color: var(--gray1);
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  border-left: 2px solid var(--red1);
}

.dashbaordContent{
  /* flex: 1; */
  padding: 20px;
  /* padding-right: 240px; */
  /* height: 100%; */
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  flex-grow: 1;
  /* flex: 1; */
}

.linkStyle{
  white-space: nowrap;
  margin: 0px 10px;
  font-size: 12px;
  color: var(--red1);
  font-family: "IRANSansMobile" !important;
  cursor: pointer;
}

.linkStyle2{
  white-space: nowrap;
  margin: 10px 10px;
  font-size: 12px;
  color: var(--black2);
  font-family: "IRANSansMobile" !important;
  cursor: pointer;
}

.menuItem{
  min-height: 40px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--gray2);
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  text-align: center;
}

.miSelected{
  background-color: var(--black3);
  color: white;
}

.miUnSelected{
  background-color: var(--gray1);
  color: var(--black1);
}

.miUnSelected:hover{
  background-color: var(--gray2);
}

/* .menuItem:hover{
  background-color: var(--gray2);
} */

.dashbaordFiltersContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* width: 100%; */
  justify-content: space-between;
  /* background-color: red; */
}

.inputStyleFilter {
  padding: 0px !important;
  /* padding-top: 4px !important; */
  background-color: var(--white1) !important;
  font-size: 12px !important;
  text-align: center !important;
  height: 32px !important;
  /* width: 75px !important; */
  min-width: 50px !important;
  max-width: 100px !important;
  font-family: "IRANSansMobile" !important;
  border-radius: 6px !important;
  border: 2px solid var(--black2);
  /* margin: 2px 0px; */
}

.inputStyleFilter:focus {
  border: 2px solid var(--red1) !important;
  /* margin: 2px 0px; */
}

.formControl{
  /* margin: 0px 20px !important; */
  min-width: 120px !important;
}

.datefield{
  font-size: 12px !important;
}

.MuiFormControl-marginNormal{
  margin: 0px !important;
}

.filterDialog{
  display: flex;
  flex-direction: column;
  direction: rtl !important;
}

.chartDialog{
  display: flex;
  flex-direction: column;
  direction: rtl !important;
  width: 100%;
  height: 100%;
  /* background-color: rebeccapurple; */
  margin: 0px !important;
  padding: 0px !important;
  
}

.MuiDialog-paper{
  /* width: 98vw !important; */
  /* height: 98vh !important;
  max-height: 800px !important;
  min-height: 300px !important; */
  min-width: 300px !important;
  max-width: 1900px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.rmdp-wrapper{
  font-family: "IRANSansMobile" !important;
}

/* .rmdp-mobile{
  font-family: "IRANSansMobile" !important;
} */

.rmdp-action-button{
  font-family: "IRANSansMobile" !important;
}

.ag-pivot-off .ag-header-group-cell{
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}


.ag-cell-label-container {
  font-family: "IRANSansMobile" !important;
  font-size: 14px;
  /* background-color: #EAEBEB; */
}

.ag-header-cell-label {
  color: var(--black1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ag-cell{
  font-family: "IRANSansMobile" !important;
  font-size: 12px;
  direction: ltr;
  padding-top: 5px;
  padding: 5px;
}

.ag-filter-toolpanel{
  font-family: "IRANSansMobile" !important;
  font-size: 14px;
}

.ag-theme-alpine .ag-filter-toolpanel {
  font-family: "IRANSansMobile" !important;
  font-size: 14px;
}

.ag-theme-alpine .ag-row-odd{
  background-color: var(--gray5) !important;
}

.ag-theme-alpine .ag-row{
  /* cursor: pointer !important;  */
  direction: rtl !important;
}

.ag-theme-alpine .ag-row-hover{
  background-color: #e5000013 !important;
}

/* .ag-row-hover{
  background-color: #35d4942a !important;
} */

.ag-theme-alpine {
  font-family: "IRANSansMobile" !important;
}

.paginatorItem{
  display: flex;
  flex-direction:row;
  align-items: center;
}

.paginatorButton{
  cursor: pointer;
  user-select: none;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginatorButton:active{
  transform: scale(0.9, 0.9);
}

.tableLoadingContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* 
.MuiPaper-elevation8Fixed{
  
}

.MuiSelect-select{
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 200px !important;
  max-width: 300px !important;
} */

.listContainer{
  
  
  display: flex;
  flex-direction: column;
  max-height: 55vh;
  overflow: auto; 
  
}

.listContainerParent{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 350px;
  min-width: 250px;

  display: flex;
  flex-direction: column;
  z-index: 1001 !important;
}

.listContainerParentBG{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;
}

.MuiOutlinedInput-notchedOutline{
  direction: rtl !important;
}

.menuIcon{
  position: absolute !important;
  right: 10px;
  display: none !important;
}

.dialogTitle{
  font-size: 16px !important;
  text-align: center !important;
  color: var(--black1);
  margin: 20px !important;
  font-family: "IRANSansMobile" !important;
}

.MuiInputBase-input{
  font-family: "IRANSansMobile" !important;
}














@media (max-width: 599px){

  .menuIcon{
    position: absolute !important;
    right: 10px;
    display: inline !important;
  }

  .dialogTitle{
    font-size: 15px !important;
  }
}